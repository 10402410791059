<template>
    <v-card class="primary--text" elevation="4" :class="color" width="100%">
        <v-card-title class="d-flex align-center justify-center">
            <v-sheet
                class="mt-n8 d-flex align-center justify-center rounded-lg"
                elevation="2"
                dark
                :width="heading ? '100%' : undefined"
                color="primary"
                rounded-pill
            >
                <h2 class="pa-4">
                    {{ heading }}
                </h2>
            </v-sheet>
        </v-card-title>
        <v-card-title
            class="text-h5 py-0 my-0"
            style="font-family: 'Poppins'; font-weight: 800"
            >{{ title }}</v-card-title
        >

        <v-card-text class="py-2 pt-0">
            <v-container fluid class="px-0">
                <v-row class="m-between px-1">
                    <template v-for="(chip, index) of text">
                        <a
                            :key="index"
                            v-if="chip.url"
                            :class="chip.class || ''"
                            :link="!!chip.url"
                            target="_blank"
                            class="chip"
                            :ripple="false"
                            :href="chip.url"
                            ><div class="white--text">
                                {{ chip.text || chip }}
                            </div></a
                        >
                        <div
                            :key="index"
                            class="chip"
                            v-else
                            :class="chip.class || ''"
                            outlined
                        >
                            <div class="ma-0">{{ chip.text || chip }}</div>
                        </div>
                    </template>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions v-if="events.length">
            <v-container>
                <v-row justify="center">
                    <v-btn
                        text
                        v-for="(event, index) in events"
                        :loading="actions.loading"
                        :disabled="actions.loading"
                        :key="index"
                        @click="$emit(event.event)"
                        :class="event.class || ''"
                        >{{ event.title || "action" }}</v-btn
                    >
                </v-row>
            </v-container>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            task_status_mapper: {
                1: "Pending",
                2: "Started",
                3: "Finished",
                4: "Cancelled",
                5: "Unfinished",
            },
            task_type_mapper: {
                public_sector: "Public Sector",
                private_sector: "Private Sector",
                pharmacy: "Pharmacy Sector",
            },
        };
    },
    props: {
        no_geolocation: {
            type: Boolean,
            default: () => {
                false;
            },
        },
        actions: {
            type: Object,
            default: () => ({ loading: false }),
        },
        task: {
            type: Object,
            required: true,
        },
        iconSmall: false,
        icon: "",
        color: {
            type: String,
            default: "#ffffff",
        },
        events: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    computed: {
        intro() {
            let intro = [];
            if (this.task?.task_type == "public_sector") {
                intro.push(
                    `${this.$tr("Establishment Name")}: ${
                        this.task.public_sector.name || "//"
                    }`
                );
                intro.push(
                    `${this.$tr("Doctor Name")}: ${
                        this.task.public_sector.doctor_name || "//"
                    }`
                );
                intro.push(
                    `${this.$tr("Alternative Doctor Name")}: ${
                        this.task.public_sector.alternative_doctor_name || "//"
                    }`
                );
                intro.push(
                    `${this.$tr("Task Classification")}: ${
                        this.task.public_sector.classification || "//"
                    }`
                );
                intro.push(
                    `${this.$tr("Speciality")}: ${
                        this.task.public_sector.speciality || "//"
                    }`
                );
                intro.push(
                    `${this.$tr("city")}: ${
                        this.task.public_sector.city || "//"
                    } `
                );
                intro.push(
                    `${this.$tr("Region")}: ${
                        this.task.public_sector.region || "//"
                    }`
                );
                intro.push(
                    `${this.$tr("Products")}: ${
                        this.task.public_sector?.products?.join?.(", ") ||
                        this.task.public_sector.product ||
                        "//"
                    } `
                );
                intro.push(
                    `${this.$tr("Comments")}: ${
                        this.task.public_sector.comment || "//"
                    }`
                );
            } else if (this.task?.task_type == "pharmacy") {
                intro.push(
                    `${this.$tr("Pharmacy Name")}: ${
                        this.task.pharmacy.name || "//"
                    }`
                );
                intro.push(
                    `${this.$tr("city")}: ${this.task.pharmacy.city || "//"}`
                );
                intro.push(
                    `${this.$tr("Region")}: ${
                        this.task.pharmacy.region || "//"
                    }`
                );
                intro.push(
                    `${this.$tr("Products")}: ${
                        this.task.pharmacy?.products?.join?.(", ") ||
                        this.task.pharmacy.product ||
                        "//"
                    }`
                );
                intro.push(
                    `${this.$tr("Person Name")}: ${
                        this.task.pharmacy.person || "//"
                    }`
                );
                intro.push(
                    `${this.$tr("Comments")}: ${
                        this.task.pharmacy.comment || "//"
                    }`
                );
            } else if (this.task?.task_type == "private_sector") {
                intro.push(
                    `${this.$tr("Establishment Name")}: ${
                        this.task.private_sector.name || "//"
                    }`
                );
                intro.push(
                    `${this.$tr("Doctor Name")}: ${
                        this.task.private_sector.doctor_name || "//"
                    }`
                );
                intro.push(
                    `${this.$tr("Alternative Doctor Name")}: ${
                        this.task.private_sector.alternative_doctor_name || "//"
                    }`
                );
                intro.push(
                    `${this.$tr("city")}: ${
                        this.task.private_sector.city || "//"
                    }`
                );
                intro.push(
                    `${this.$tr("Region")}: ${
                        this.task.private_sector.region || "//"
                    }`
                );
                intro.push(
                    `${this.$tr("Products")}: ${
                        this.task.private_sector?.products?.join?.(", ") ||
                        this.task.private_sector.product ||
                        "//"
                    }`
                );
                intro.push(
                    `${this.$tr("Comments")}: ${
                        this.task.private_sector.comment || "//"
                    }`
                );
            }
            return intro;
        },
        started() {
            if (this.task.status == 2 || this.task.status == 3) {
                const string = [];
                const start_details = this.task.start_json;
                const Start_time = new Date(start_details.timestamp);
                const start_location = `https://www.google.com/maps/search/?api=1&query=${this.task.start_json.position.latitude},${this.task.start_json.position.longitude}`;

                string.push(
                    `${this.$tr("started at")}: ${Start_time.toLocaleString()}`
                );

                this.no_geolocation ||
                    string.push({
                        class: "success",
                        text: this.$tr("Start Position"),
                        url: start_location,
                    });
                return string;
            } else {
                return [];
            }
        },
        cancelled() {
            if (this.task.status == 4) {
                const finish_details = this.task.finish_json;
                const finish_time = new Date(finish_details.timestamp);
                const string = [];
                string.push(
                    `${this.$tr(
                        "cancelled at"
                    )}: ${finish_time.toLocaleString()}`
                );
                string.push(
                    `${this.$tr("cancelled because")}: ${finish_details.why}`
                );
                return string;
            } else {
                return [];
            }
        },
        finished() {
            if (this.task.status == 3) {
                const string = [];
                const finish_details = this.task.finish_json;
                const finish_time = new Date(finish_details.timestamp);
                const end_location = `https://www.google.com/maps/search/?api=1&query=${this.task.finish_json.position.latitude},${this.task.finish_json.position.longitude}`;

                string.push(
                    `${this.$tr(
                        "finished at"
                    )}: ${finish_time.toLocaleString()}`
                );
                if (
                    finish_details[this.task.task_type].alternative_doctor_name
                ) {
                    string.push(
                        `${this.$tr("Alternative Doctor")}: ${
                            finish_details[this.task.task_type]
                                .alternative_doctor_name
                        }`
                    );
                }
                string.push(
                    `${this.$tr("Finish Comments")}: ${
                        finish_details[this.task.task_type].comment
                    }`
                );

                this.no_geolocation ||
                    string.push({
                        class: "success",
                        text: this.$tr("Finish Position"),
                        url: end_location,
                    });
                return string;
            } else {
                return [];
            }
        },
        text() {
            return [
                ...this.intro,
                ...this.started,
                ...this.cancelled,
                ...this.finished,
            ];
        },
        title() {
            console.log("getting title", this.task);
            return this.task_type_mapper[this.task?.task_type];
        },
        heading() {
            return this.task_status_mapper[this.task.status];
        },
    },
};
</script>

<style lang="sass">
.v-card.v-card--material
> .v-card__title
    > .v-card--material__title
        flex: 1 1 auto
        word-break: break-word

.m-between
    & *
        margin: 4px 
.chip
    border: 1px solid #00000066
    color: #00000099
    // background: #ffffff30
    background: linear-gradient(45deg, rgba(226,226,226,0.2) 0%, rgba(255,248,248,0.2) 37%, rgba(187,187,187,0.18) 100%)
    text-decoration: none
    padding: 4px 8px
    font-weight: 500
    border-radius: 8px
    > div
        padding: 0px
        margin: 0px
</style>